import React from 'react'

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withStyles,
  Checkbox,
} from '@material-ui/core'
import { Cancel, Delete, Edit, Save } from '@material-ui/icons'
import { StatusRange, TelemetryType, TelemetryStatus } from 'api/alertservice'
import { getStatusLensColor } from 'components/utils'
import { ValidatorForm } from 'react-material-ui-form-validator'
import theme from 'theme'
import ThresholdsTextValidator from './ThresholdsTextValidator'
import { StatusThresholdMinMaxProperties } from './ThresholdsTextValidatorBase'
import { formatActualTankLevelFeet } from 'components/utils/formatter'
import * as Constants from './constants'
import { celsiusToFahrenheit } from 'components/utils/converter'
import * as LDClient from 'launchdarkly-js-client-sdk'

interface Props {
  isEdited: boolean
  threshold: StatusRange
  editedStatusRange?: StatusRange
  index: number
  handleEditButtonClick(index, threshold): void
  handleRemoveButtonClick(index: number): void
  handleCancelButtonClick(): void
  handleSaveThreshold(): void
  formRef: React.RefObject<ValidatorForm>
  isSaveEnabled?: boolean
  telemetryType: TelemetryType
  handleThresholdChange(
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ): void
  checkIntersectingRule(minThreshold: number, maxThreshold: number): boolean
  isAdmin: boolean
  tenantId: number
  readonly launchDarklyClientsideId?: string
}

const StyledListItemText = withStyles({
  primary: {
    display: 'flex',
  },
})(ListItemText)

interface State {
  featureFlags: any
  showThresholdPumpControl: boolean
}

class ThresholdRange extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      featureFlags: null,
      showThresholdPumpControl: false,
    }
  }

  isTank = this.props.telemetryType === TelemetryType.TankLevel
  flags: any
  showThresholdPumpControl = false

  private featureFlag() {
    const user: LDClient.LDUser = {
      key: this.props.tenantId.toString(),
    }

    const LaunchDarklyClientsideId = this.props.launchDarklyClientsideId!
    const options: LDClient.LDOptions = { bootstrap: 'localStorage' }
    const client = LDClient.initialize(LaunchDarklyClientsideId, user, options)

    client
      .waitForInitialization()
      .then(() => {
        const flags = client.allFlags()
        this.setState({
          featureFlags: flags,
          showThresholdPumpControl: flags && flags.ShowPump === true,
        })
      })
      .catch((err) => {
        console.error(
          '🚀 ~ file: AllSites.tsx ~ client.waitForInitialization ~ err',
          err
        )
      })
  }

  public componentDidMount() {
    this.featureFlag()
  }

  public render() {
    if (this.props.telemetryType === TelemetryType.PumpControl) {
      return this.renderPumpControlThreshold(
        this.props.threshold,
        this.props.index
      )
    } else if (this.props.telemetryType === TelemetryType.FlareStatus) {
      return this.renderFlareThreshold(this.props.threshold, this.props.index)
    } else if (this.props.isEdited) {
      return this.renderEditedRange(this.props.threshold, this.props.index)
    } else {
      return this.renderReadOnlyRange(this.props.threshold, this.props.index)
    }
  }

  private readonly renderRange = (
    threshold: StatusRange,
    index: number,
    content: React.ReactNode
  ) => {
    const { status } = threshold

    return (
      <ListItem key={index} style={{ padding: 0 }}>
        <ListItemAvatar style={{ minWidth: theme.spacing(3) }}>
          <div
            style={{
              backgroundColor: getStatusLensColor(status),
              borderRadius: '50%',
              width: theme.spacing(3),
              height: theme.spacing(3),
            }}
          />
        </ListItemAvatar>
        <StyledListItemText>{content}</StyledListItemText>
      </ListItem>
    )
  }

  private readonly renderReadOnlyRange = (
    threshold: StatusRange,
    index: number
  ) => {
    const content = (
      <div
        style={{
          display: 'flex',
          marginBottom: theme.spacing(0.5),
        }}
      >
        <Typography
          style={{
            width: '90px',
            margin: theme.spacing(0.5, 0.5, 0, 1),
          }}
          variant="body1"
        >
          {this.getFormattedThresholdValue(
            this.props.telemetryType,
            threshold.minThreshold
          )}
        </Typography>
        <Typography
          style={{
            width: '90px',
            margin: theme.spacing(0.5, 0.5, 0, 1),
          }}
          variant="body1"
        >
          {this.getFormattedThresholdValue(
            this.props.telemetryType,
            threshold.maxThreshold
          )}
        </Typography>
        {this.isTank && this.props.isAdmin && (
          <Checkbox
            checked={threshold.isHighPriority}
            readOnly
            disabled={threshold.status === TelemetryStatus.Green}
            style={{
              pointerEvents: 'none',
              width: '90px',
              margin: theme.spacing(0.5, 0.5, 0, 1),
            }}
          />
        )}
        {this.isTank &&
          this.state.showThresholdPumpControl &&
          this.props.isAdmin && (
            <Checkbox
              checked={threshold.allowPumpControl}
              readOnly
              disabled={threshold.status !== TelemetryStatus.Red}
              style={{
                pointerEvents: 'none',
                width: '90px',
                margin: theme.spacing(0.5, 0.5, 0, 1),
              }}
            />
          )}
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          onClick={() => this.props.handleEditButtonClick(index, threshold)}
        >
          <Edit />
        </IconButton>
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          onClick={() => this.props.handleRemoveButtonClick(index)}
        >
          <Delete />
        </IconButton>
      </div>
    )

    return this.renderRange(threshold, index, content)
  }

  private readonly renderFlareThreshold = (
    threshold: StatusRange,
    index: number
  ) => {
    const content = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(0.5),
        }}
      >
        <Typography
          style={{
            margin: theme.spacing(0.5, 2, 0, 1),
          }}
          variant="body1"
        >
          {`Status: ${
            threshold.status === TelemetryStatus.Green ? 'ON' : 'OFF'
          }`}
        </Typography>
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          onClick={() => this.props.handleRemoveButtonClick(index)}
        >
          <Delete />
        </IconButton>
      </div>
    )

    return this.renderRange(threshold, index, content)
  }

  private readonly renderPumpControlThreshold = (
    threshold: StatusRange,
    index: number
  ) => {
    const content = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(0.5),
        }}
      >
        <Typography
          style={{
            margin: theme.spacing(0.5, 2, 0, 1),
          }}
          variant="body1"
        >
          {`Status: ${
            threshold.status === TelemetryStatus.Green ? 'ON' : 'OFF'
          }`}
        </Typography>
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          onClick={() => this.props.handleRemoveButtonClick(index)}
        >
          <Delete />
        </IconButton>
      </div>
    )

    return this.renderRange(threshold, index, content)
  }

  private readonly getFormattedThresholdValue = (
    type: TelemetryType,
    thresholdValue: number
  ) => {
    switch (type) {
      case TelemetryType.TankLevel:
      case TelemetryType.BalancedTank:
        return formatActualTankLevelFeet(thresholdValue)
      case TelemetryType.HeaterTemp:
        return celsiusToFahrenheit(thresholdValue)
      default:
        return thresholdValue
    }
  }

  private readonly renderEditedRange = (
    threshold: StatusRange,
    index: number
  ) => {
    const { editedStatusRange: editedThreshold, isSaveEnabled } = this.props
    const content = (
      <ValidatorForm
        ref={this.props.formRef}
        instantValidate={true}
        onSubmit={this.props.handleSaveThreshold}
        style={{
          display: 'flex',
        }}
      >
        {this.renderRangeTextFiled(
          'From',
          Constants.FromLessThanToRuleName,
          'Must be less than To',
          editedThreshold!.minThreshold,
          index,
          'minThreshold'
        )}
        {this.renderRangeTextFiled(
          'To',
          Constants.ToGreaterThanFromRuleName,
          'Must be greater than From',
          editedThreshold!.maxThreshold,
          index,
          'maxThreshold'
        )}
        {this.isTank && this.props.isAdmin && (
          <Checkbox
            checked={editedThreshold!.isHighPriority}
            onChange={(e) =>
              this.props.handleThresholdChange(
                e.target.checked.toString(),
                index,
                'isHighPriority'
              )
            }
            disabled={threshold.status === TelemetryStatus.Green}
            style={{ width: '90px', margin: theme.spacing(0.5, 0.5, 0, 1) }}
          />
        )}
        {this.isTank &&
          this.state.showThresholdPumpControl &&
          this.props.isAdmin && (
            <Checkbox
              checked={editedThreshold!.allowPumpControl}
              onChange={(e) =>
                this.props.handleThresholdChange(
                  e.target.checked.toString(),
                  index,
                  'allowPumpControl'
                )
              }
              disabled={threshold.status !== TelemetryStatus.Red}
              style={{ width: '90px', margin: theme.spacing(0.5, 0.5, 0, 1) }}
            />
          )}
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          type="submit"
          disabled={!isSaveEnabled}
        >
          <Save />
        </IconButton>
        <IconButton
          style={{ minWidth: 'auto' }}
          color="primary"
          size="small"
          onClick={this.props.handleCancelButtonClick}
        >
          <Cancel />
        </IconButton>
      </ValidatorForm>
    )

    return this.renderRange(threshold, index, content)
  }

  private renderRangeTextFiled(
    name,
    ruleName,
    ruleMessage,
    value,
    index,
    property: keyof Pick<
      StatusRange,
      'minThreshold' | 'maxThreshold' | 'isHighPriority' | 'allowPumpControl'
    >
  ) {
    const {
      telemetryType,
      handleThresholdChange,
      checkIntersectingRule,
      editedStatusRange: editedThreshold,
    } = this.props

    return (
      <ThresholdsTextValidator
        telemetryType={telemetryType}
        name={name}
        ruleName={ruleName}
        ruleMessage={ruleMessage}
        value={value}
        index={index}
        property={property}
        handleThresholdChange={handleThresholdChange}
        checkIntersectingRule={checkIntersectingRule}
        editedThreshold={editedThreshold}
      />
    )
  }
}

export default ThresholdRange
